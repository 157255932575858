<style lang="scss" scoped>
.con-nav-footer {
  padding-left: 20rem;
}
.part {
  width: 240px;
}
.wage {
  width: 200px;
}
.number {
  width: 140px;
  text-align: right;
}
.Dc {
  width: 100px;
}
.action {
  width: 40px;
}
.numberP {
  width: 140px;
  text-align: right;
}
.price {
  width: 170px;
  text-align: right;
}
</style>
<style scoped>
@media only screen and (min-width: 500px) {
  .alert-fixed {
    position: fixed;
    top: 50px;
    left: 65%;
    width: 30%;
    z-index: 9999;
    border-radius: 0px;
  }
}
</style>

<script>
import { required, numeric } from "vuelidate/lib/validators";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import Swal from "sweetalert2";
import useNetw from "@useNetw";

/**
 * Form validation component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
  },
  page: {
    title: "แก้ไขการรับอะไหล่เข้าคลัง",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      loading: false,
      enabled: false,
      modalShow: false,
      message: "โปรดระบุข้อมูล",
      title: "แก้ไขการรับอะไหล่เข้าคลัง",
      items: [
        {
          text: "อะไหล่",
        },
        {
          text: "รับอะไหล่เข้าคลัง",
          href: "/parts/recieve-inventory",
        },
        {
          text: "แก้ไขการรับอะไหล่เข้าคลัง",
          active: true,
        },
      ],
      optionTypeDc: [
        {
          name: "บาท",
          bath: "bath",
        },
        {
          name: "เปอรเซ็น",
          per: "per",
        },
      ],
      //? tosts
      dismissSecs: 3,
      dismissCountDown: 0,
      resalert: "",


      //! RC
      partId: this.$route.params.partId,
      rowSup: [],
      partsCode: [],
      nameState: null,
      selectMode: "multi",
      selected: [],
      isLoading: "",
      id: "",
      page: "",
      total: "",
      perPage: 10,
      currentPage: 1,
      rowPoParts: [],
      rowParts: [],
      rowPart: [],
      localDataBranchUser: [],
      localDataBranchIdUser: [],
      orderDate: "",
      branchOrder: null,
      orderType: null,
      orderCompany: null,
      orderPer: null,
      options: [],
      selectedPart: "",
      // รวมpo
      poTotalPrice: 0, // มูลค่ารวมอะไหล่
      poDcPer: 0,
      poDcPrice: 0,
      poNetPrice: 0,
      poVatPer: 0,
      poVatPrice: 0,
      poGrandTotalPrice: 0,

      //? receipts
      receipts: {
        selectPart: {},
        pricePerOne: 0,
        amount: 0,
        totalPrice: 0,
        dcPer: 0, //* ส่วนลด %
        dcPrice: 0, //* totalprice * dcper
        netPrice: 0, //* totalprice + (totalprice * dcper)
        dcMethod: "",

        vatPer: 7, //* %ภาษี
        vatPrice: 0, //* netPrice * vatPer
        grandTotalPrice: 0, //* มูลค่ารวมคิดภาษี = netPrice + (netPrice * vatPer)

        branchId: "",
        supId: "",
        Date: "",
        stockId: "",

        amountPartPO: "",
        shippingNumber: "",
        shippingDate: "",
        taxInvoiceNumber: "",
        taxInvoiceDate: "",
        credit: "",
        dueDate: "",
        //? Data Response receiptId
        receiptId: "",
        //? data PO select
        poCode: [],
        poData: [],
      },
      searchform: {
        partsCode: "",
      },
      submit: false,
      submitted: false,
      submitform: false,
      isHidden: true,

      //? data Stock
      optionMasterST: [],
      masterST: [],

      //? data PO
      optionsPO: [],
      selectDataPO: [],

      //? data part PO
      rcptTotalPrice: 0,
      rcptDcPer: 0,
      rcptDcPrice: 0,
      rcptNetPrice: 0,
      rcptVatPer: 7,
      rcptVatPrice: 0,
      rcptGrandTotalPrice: 0,

      //? data RC
      rowsRC: [],
      selectDataRC: [],
      showOverlayFormConcludeRC: false,

      //? fieldsPartPO
      filterOnPO: [],
      rowDataPO: [], //? Data ที่ต่อ API
      perPagePO: 10, //?จำนวต่อหน้า
      currentPagePO: 1, //?หน้าปัจจุบัน
      filterPO: null, // ?คำค้นหา
      pageOptionsPO: [5, 10, 25, 50, 100], //? แสดงกี่คอลัมน์ต่อหน้า
      total_pagesPO: "", //?จำนวนหน้าทั้งหมด
      pagePO: "", //?หน้าที่
      sortBy: "poDate",
      sortDesc: true,
      totalPO: "", //? จำนวนหน้าทั้งหมด
      totalRowsPO: 1,
      selectedPO: [],
      fieldsPartPO: [
        {
          key: "poDate",
          label: "วันที่(ใบสังซื้อ)",
          sortable: true,
        },
        {
          key: "poCode",
          label: "รหัสใบสั่งซื้่อ",
          sortable: true,
        },
        {
          key: "partCode",
          sortable: true,
          label: "รหัสอะไหล่",
        },
        {
          key: "partNameTh",
          sortable: true,
          label: "ชื่ออะไหล่",
        },
        {
          key: "amount",
          label: "จำนวน",
          sortable: true,
        },
        {
          key: "accruedAmount",
          label: "คงค้าง",
          sortable: true,
        },
        {
          key: "action",
          label: "เพิ่ม",
        },
      ],
      //? fieldsPartPO
      status: "",
      filterOnRC: [],
      rowDataRC: [], //? Data ที่ต่อ API
      perPageRC: 10, //?จำนวต่อหน้า
      currentPageRC: 1, //?หน้าปัจจุบัน
      filterRC: null, // ?คำค้นหา
      pageOptionsRC: [5, 10, 25, 50, 100], //? แสดงกี่คอลัมน์ต่อหน้า
      total_pagesRC: "", //?จำนวนหน้าทั้งหมด
      pageRC: "", //?หน้าที่
      totalRC: "", //? จำนวนหน้าทั้งหมด
      totalRowsRC: 1,
      selectedRC: [],
      statusRC: "",
      partSelect: "",
      fieldsPartRC: [
        //? field RC Part
        {
          key: "poDate",
          label: "วันที่(ใบสังซื้อ)",
          sortable: true,
        },
        {
          key: "poCode",
          label: "รหัสใบสั่งซื้่อ",
          sortable: true,
        },
        {
          key: "partCode",
          sortable: true,
          label: "รหัสอะไหล่",
        },
        {
          key: "partNameTh",
          sortable: true,
          label: "ชื่ออะไหล่",
        },
        {
          key: "amount",
          label: "จำนวน",
          sortable: true,
        },
        {
          key: "poAccruedAmount",
          label: "คงค้าง",
          sortable: true,
        },
        {
          key: "totalPrice",
          label: "ยอดรวม",
          sortable: true,
        },
        {
          key: "dcPer",
          label: "%ส่วนลด",
          sortable: true,
        },
        {
          key: "dcPrice",
          label: "ส่วนลด(บาท)",
          sortable: true,
        },
        {
          key: "vatPer",
          label: "%ภาษี",
          sortable: true,
        },
        {
          key: "vatPrice",
          label: "ภาษี(บาท)",
          sortable: true,
        },
        {
          key: "grandTotalPrice",
          label: "สุทธิ",
          sortable: true,
        },
        {
          key: "action",
          label: "แก้ไข/ลบ",
        },
      ],
    };
  },
  validations: {
    receipts: {
      amount: {
        required,
        numeric,
      },
      branchId: {
        required,
      },
      Date: {
        required,
      },
      vatPer: {
        required,
      },
    },
  },
  computed: {},

  mounted() {},
  toggleForm() {
    this.showmodelForm = !this.showmodelForm;
  },
  created() {
    this.receipts.receiptId = this.$route.params.receiptId;
    this.getLocalData();
    this.getParts();

    this.getSup();
    this.getMasterPartST();
    this.getRCData();

    this.getRCPartData();
    this.totalPriceRC();
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert(response) {
      this.dismissCountDown = this.dismissSecs;
      this.resalert = response;
    },
    PutStatusRC() {
      useNetw
        .put("api/part-receipt/submit", {
          receiptId: this.$route.params.receiptId,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.getRCData();

          this.getRCPartData();
          this.totalPriceRC();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.$refs.modalEditPartRT.hide();
        });
    },
    comfirmPutStatusRC() {
      Swal.fire({
        title: "ต้องการยิืนยันการรับอะไหล่เข้าคลีงใช่หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.value) {
          this.PutStatusRC();
        }
      });
    },
    getRCData() {
      this.isLoading = true;
      useNetw
        .get("api/part-receipt/show	", {
          params: {
            receiptId: this.$route.params.receiptId,
          },
        })
        .then((response) => {
          //con
          // // console.log("RC ==>  ", response.data);
          // this.branchId = response.data.data.branchId;
          this.receipts.branchId = {
            branchId: response.data.data.branchId,
            nameTh: response.data.data.branchName,
          };
          this.receipts.supId = {
            supId: response.data.data.supId,
            nameTh: response.data.data.supplierNameTh,
          };
          this.receipts.Date = response.data.data.receiptDate;
          this.receipts.vatPer = response.data.data.vatPer;
          this.receipts.stockId = {
            stockId: response.data.data.stockId,
            nameTh: response.data.data.stockNameTh,
          };
          this.receipts.shippingNumber = response.data.data.shippingNumber;
          this.receipts.shippingDate = response.data.data.shippingDate;
          this.receipts.taxInvoiceNumber = response.data.data.taxInvoiceNumber;
          this.receipts.taxInvoiceDate = response.data.data.taxInvoiceDate;
          this.receipts.credit = response.data.data.credit;
          this.receipts.dueDate = response.data.data.dueDate;
          this.statusRC = response.data.data.status;
          this.isLoading = false;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.isLoading = false; //skeleton false
        });
    },

    putRC() {
      this.totalPriceRC();
      useNetw
        .put("api/part-receipt/update", {
          receiptId: this.receipts.receiptId,
          receiptDate: this.receipts.Date,
          shippingNumber: this.receipts.shippingNumber,
          shippingDate: this.receipts.shippingDate,
          taxInvoiceNumber: this.receipts.taxInvoiceNumber,
          taxInvoiceDate: this.receipts.taxInvoiceDate,
          credit: this.receipts.credit,
          dueDate: this.receipts.dueDate,

          totalPrice: this.rcptTotalPrice,
          dcPer: this.rcptDcPer,
          dcPrice: this.rcptDcPrice,
          netPrice: this.rcptNetPrice,
          vatPer: this.rcptVatPer,
          vatPrice: this.rcptVatPrice,
          grandTotalPrice: this.rcptGrandTotalPrice,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$router.push({ name: "recieve-inventory" });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },
    delPartPO(rowsRC) {
      // this.selectDataPO = rowsRC.item;
      // console.log(rowsRC);
      this.totalPriceRC();
      useNetw
        .delete("api/part-receipt/part/delete", {
          data: {
            receiptId: this.receipts.receiptId,
            partId: rowsRC.item.partId,

            rcptTotalPrice: this.rcptTotalPrice - rowsRC.item.totalPrice,
            rcptDcPer: this.rcptDcPer,
            rcptDcPrice: this.rcptDcPrice,
            rcptNetPrice: this.rcptNetPrice - rowsRC.item.netPrice,
            rcptVatPer: this.rcptVatPer,
            rcptVatPrice: this.rcptVatPrice - rowsRC.item.vatPrice,
            rcptGrandTotalPrice:
              this.rcptGrandTotalPrice - rowsRC.item.grandTotalPrice,
          },
        })
        .then((response) => {
          this.showAlert(response.data.message);
          this.getRCPartData();
          this.totalPriceRC();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.overlayFlag = false; //skeleton false
        });
    },

    ConfirmDelPartPO(rowsRC) {
      // // console.log('DEL==>',rowsRC.item.poId);
      Swal.fire({
        title: "ต้องการยกเลิกการนำอะไหล่เข้าคลังใช่หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#f46a6a",
        cancelButtonColor: "#34c38f",
        confirmButtonText: "ยิืนยันการลบ",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.value) {
          this.delPartPO(rowsRC);
        }
      });
    },
    putPartPO() {
      this.totalPriceUpdateRC();

      useNetw
        .put("api/part-receipt/part/update", {
          receiptId: this.receipts.receiptId,
          partId: this.selectDataPO.partId,
          poId: this.selectDataPO.poId,
          amount: this.receipts.amountPartPO,
          pricePerOne: this.selectDataPO.pricePerOne,
          totalPrice:
            this.selectDataPO.pricePerOne * this.receipts.amountPartPO,
          dcPer: this.receipts.dcPer,
          dcPrice: this.receipts.dcPrice,
          netPrice: this.receipts.netPrice,
          vatPer: this.receipts.vatPer,
          vatPrice: this.receipts.vatPrice,
          grandTotalPrice: this.receipts.grandTotalPrice,
          poOrderAmount: this.receipts.amountPartPO,
          poAccruedAmount:
            this.selectDataPO.poAccruedAmount +
            this.selectDataPO.amount -
            this.receipts.amountPartPO,

          rcptTotalPrice: this.rcptTotalPrice,
          rcptDcPer: this.rcptDcPer,
          rcptDcPrice: this.rcptDcPrice,
          rcptNetPrice: this.rcptNetPrice,
          rcptVatPer: this.rcptVatPer,
          rcptVatPrice: this.rcptVatPrice,
          rcptGrandTotalPrice: this.rcptGrandTotalPrice,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.selectDataPO = [];
          this.$refs.modalEditPartPO.hide();
          this.getRCPartData();
          this.totalPriceRC();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },
    editPartPO(rowsRC) {
      this.selectDataPO = rowsRC.item;
      // console.log("SelectedDataPArtPO Edit = ", this.selectDataPO);
      this.receipts.amountPartPO = rowsRC.item.amount;

      this.$refs.modalEditPartPO.show();
      // // console.log('rowsRC ===> ', this.selectDataPO);
    },
    totalPriceUpdateRC() {
      // this.rowsRC.splice(0, 1, this.receipts);
      // // console.log(this.rowsRC);

      // console.log("partId", this.selectDataPO.partId);
      let arr = this.rowsRC.filter(
        (item) => item.partId !== this.selectDataPO.partId
      );
      // console.log("Data del drr => ", arr);
      const pushArr = {
        totalPrice: this.selectDataPO.pricePerOne * this.receipts.amountPartPO,
        netPrice: this.receipts.netPrice,
        partId: this.selectDataPO.partId,
        dcPrice: this.receipts.dcPrice,
        vatPrice: this.receipts.vatPrice,
        grandTotalPrice: this.receipts.grandTotalPrice,
      };
      arr.push(pushArr);
      // console.log("arr After push =>", arr);

      this.rowsRC = arr;

      let sumNetPrice = 0;

      this.rowsRC.forEach(function(item) {
        sumNetPrice += parseFloat(item.netPrice);
        // console.log(item.netPrice);
      });

      this.rcptTotalPrice = sumNetPrice.toFixed(2);
      // this.rcptTotalPrice = 1000;
      this.rcptDcPrice = this.rcptTotalPrice * (this.receipts.dcPer / 100);
      this.rcptNetPrice = this.rcptTotalPrice - this.rcptDcPrice;
      this.rcptVatPrice = this.rcptNetPrice * (this.receipts.vatPer / 100);
      this.rcptGrandTotalPrice = this.rcptNetPrice + this.rcptVatPrice;

      // // console.log('ยอดรวม=', this.rcptTotalPrice);
      // // console.log('ส่วนลด=', this.rcptDcPrice);
      // // console.log('ราคาลด=', this.rcptNetPrice);
      // // console.log('ภาษี=', this.rcptVatPrice);
      // // console.log('ราคารวมทั้งหมด=', this.rcptGrandTotalPrice );
    },

    totalPriceRC() {
      // let sumVatPrice = 0;
      let sumNetPrice = 0;
      // let sumDcPrice = 0;
      // let sumTotalPrice = 0;

      // this.rowsRC.forEach(function(item) {
      //   sumTotalPrice += item.totalPrice;
      //   sumVatPrice += item.vatPrice;
      //   sumNetPrice += item.netPrice;
      //   sumDcPrice += item.dcPer;
      // });

      if (this.rowsRC.length < 0) {
        const pushArr = {
          totalPrice:
            this.selectDataPO.pricePerOne * this.receipts.amountPartPO,
          netPrice:
            this.receipts.netPrice === isNaN ? 0 : this.receipts.netPrice,
          partId: this.selectDataPO.partId,
          dcPrice: this.receipts.dcPrice,
          vatPrice: this.receipts.vatPrice,
          grandTotalPrice: this.receipts.grandTotalPrice,
        };
        this.rowsRC.push(pushArr);
      }

      this.rowsRC.forEach(function(item) {
        sumNetPrice += parseFloat(item.netPrice);
        // console.log(item.netPrice.toFixed(2));
      });

      this.rcptTotalPrice = sumNetPrice;
      // this.rcptTotalPrice = 1000;
      this.rcptDcPrice = this.rcptTotalPrice * (this.receipts.dcPer / 100);
      this.rcptNetPrice = this.rcptTotalPrice - this.rcptDcPrice;
      this.rcptVatPrice = this.rcptNetPrice * (this.receipts.vatPer / 100);
      this.rcptGrandTotalPrice = this.rcptNetPrice + this.rcptVatPrice;

      // if (this.selectDataPO.pricePerOne != undefined) {
      //   this.rcptTotalPrice = sumTotalPrice;
      //   this.rcptDcPrice = sumDcPrice + this.receipts.dcPrice;
      //   this.rcptNetPrice = sumNetPrice + this.receipts.netPrice;

      //   this.rcptVatPrice = sumVatPrice + parseFloat(this.receipts.vatPrice);
      //   this.rcptGrandTotalPrice = sumTotalPrice + this.rcptVatPrice;
      //   // // console.log('this.rcptVatPrice',this.rcptVatPrice);
      // } else {
      //   this.rcptTotalPrice = sumTotalPrice;
      //   this.rcptDcPrice = sumDcPrice;
      //   this.rcptNetPrice = sumNetPrice;
      //   this.rcptVatPrice = sumVatPrice;
      //   this.rcptGrandTotalPrice = sumTotalPrice;
      // }
      // // console.log('ยอดรวม=', this.rcptTotalPrice);
      // // console.log('ส่วนลด=', this.rcptDcPrice);
      // // console.log('ราคาลด=', this.rcptNetPrice);
      // // console.log('ภาษี=', this.rcptVatPrice);
      // // console.log('ราคารวมทั้งหมด=', this.rcptGrandTotalPrice);
    },

    getRCPartData() {
      // this.isLoading = true; //skeleton true
      useNetw
        .get("api/part-receipt/parts", {
          params: {
            receiptId: this.receipts.receiptId,
          },
        })
        .then((response) => {
          this.rowsRC = response.data.data;
          // // console.log('inpData ===>',this.rowsRC);
          // // console.log(response);
          this.totalPriceRC();
        })
        .catch((err) => {
          if (err.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(
                err.response.data.message + "<br>" + err.response.data.message
              ),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          this.isLoading = false;
        });
    },

    postPartPO: function() {
      // this.hidemodalWage();
      // this.showOverlayFormPackageWage = true;
      // // console.log(this.WagePostForm.amountP);
      // !this.showOverlayFormPackage = true;
      this.totalPriceRC();
      const partPOFormData = new FormData();

      partPOFormData.append("receiptId", this.receipts.receiptId);
      partPOFormData.append("partId", this.selectDataPO.partId);
      partPOFormData.append("poId", this.selectDataPO.poId);
      partPOFormData.append("amount", this.receipts.amountPartPO);
      partPOFormData.append("pricePerOne", this.selectDataPO.pricePerOne);
      partPOFormData.append(
        "totalPrice",
        this.selectDataPO.pricePerOne * this.receipts.amountPartPO
      );
      partPOFormData.append("dcPer", this.receipts.dcPer);
      partPOFormData.append("dcPrice", this.receipts.dcPrice);
      partPOFormData.append("netPrice", this.receipts.netPrice); //ส่วนลด
      partPOFormData.append("vatPer", this.receipts.vatPer); //ภาษี
      partPOFormData.append("vatPrice", this.receipts.vatPrice); //ภาษี บาท
      partPOFormData.append("grandTotalPrice", this.receipts.grandTotalPrice);
      partPOFormData.append(
        "poOrderAmount",
        this.selectDataPO.accruedAmount - this.receipts.amountPartPO
      );
      partPOFormData.append(
        "poAccruedAmount",
        this.selectDataPO.accruedAmount - this.receipts.amountPartPO
      );

      //* จบส่วน PO
      partPOFormData.append(
        "rcptTotalPrice",
        this.rcptTotalPrice === 0
          ? (this.rcptTotalPrice = this.receipts.grandTotalPrice)
          : this.rcptTotalPrice + this.receipts.grandTotalPrice
      );
      partPOFormData.append("rcptDcPer", this.rcptDcPer);
      partPOFormData.append("rcptDcPrice", this.rcptDcPrice);
      partPOFormData.append("rcptNetPrice", this.rcptNetPrice);
      partPOFormData.append("rcptVatPer", this.rcptVatPer);
      partPOFormData.append("rcptVatPrice", this.rcptVatPrice);
      partPOFormData.append(
        "rcptGrandTotalPrice",
        this.rcptGrandTotalPrice === 0
          ? (this.rcptGrandTotalPrice = this.receipts.grandTotalPrice)
          : this.rcptGrandTotalPrice + this.receipts.grandTotalPrice
      );

      useNetw
        .post("api/part-receipt/part/store", partPOFormData)
        .then((response) => {
          
          this.getRCPartData();
          this.totalPriceRC();
          this.selectDataPO = [];

          this.showAlert(response.data.message);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.$refs.modalAddPartPO.hide();
          // this.showOverlayFormPackageWage = false;
        });
    },

    popupModalAddPO(optionsPO) {
      // console.log("options =>", optionsPO);
      this.selectDataPO = optionsPO.item;
      // // console.log(this.selectDataPO);
      this.receipts.amountPartPO = optionsPO.item.receiptAmount;
      // // console.log('SelectedDataPArtPO Edit = ', this.selectDataPO);
      // // console.log('options', optionsPO.item);
      this.$refs.modalAddPartPO.show();
    },
    popupModalAddPOwMulti(optionsPO) {
      // console.log("options =>", optionsPO);
      this.selectDataPO = optionsPO;
      // // console.log(this.selectDataPO);
      this.receipts.amountPartPO = optionsPO.receiptAmount;
      // // console.log('SelectedDataPArtPO Edit = ', this.selectDataPO);
      // // console.log('options', optionsPO.item);
      this.$refs.modalAddPartPO.show();
    },
    getPoData() {
      this.isLoading = true; //skeleton true
      useNetw
        .get("api/part-receipt/purchase-orders", {
          params: {
            page: 1,
            perPage: 10,
            receiptId: this.receipts.receiptId,
            poCode: this.receipts.poCode,
            // branchId: this.branchId,
          },
        })
        .then((response) => {
          // // console.log("Master Stock ==> " , response.data.data);
          this.optionsPO = response.data.data;
          this.$refs["modalPO"].show();

          // // console.log(this.optionsPO);
          // // console.log(response);
        })
        .catch((err) => {
          if (err.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(
                err.response.data.message + "<br>" + err.response.data.message
              ),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.isLoading = false; //skeleton false
        });
    },

    postReceipts() {
      this.overlayFlag = true;

      const bodyFormData = new FormData();

      bodyFormData.append("branchId", this.receipts.branchId.branchId);
      bodyFormData.append("supId", this.receipts.supId.supId);
      bodyFormData.append("receiptDate", this.receipts.Date);
      // bodyFormData.append("poDate", this.receipts.Date);
      bodyFormData.append("vatPer", this.receipts.vatPer);
      bodyFormData.append("stockId", this.receipts.stockId);
      bodyFormData.append("shippingNumber", this.receipts.shippingNumber);
      bodyFormData.append("shippingDate", this.receipts.shippingDate);
      bodyFormData.append("taxInvoiceNumber", this.receipts.taxInvoiceNumber);
      bodyFormData.append("taxInvoiceDate", this.receipts.taxInvoiceDate);
      bodyFormData.append("credit", this.receipts.credit);
      bodyFormData.append("dueDate", this.receipts.dueDate);

      useNetw
        .post("api/part-receipt/store", bodyFormData)
        .then((response) => {
          // // console.log('OK');
          // // console.log(response);

          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );

          this.receipts.receiptId = response.data.receiptId;
          // // console.log('Response ==> ', response.data);
          // // console.log('receiptId ==> ', this.receiptId);
          this.isHidden = true;

          // // console.log("postRoWage", this.roId);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.response.data.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.getPoShow(this.poId);
          this.overlayFlag = false;
          // this.$router.push({ name: "branch-wages" });
        });
    },
    getParts: function() {
      // this.overlayFlag = true;
      useNetw
        .get("api/part-receipt/parts", {
          // params: {
          //   page: 1,
          //   perPage: 100, //เฉพาะค้นหา สูงสุด 100
          //   branchId: [this.branchId],
          // },
          params: {
            receiptId: this.receipts.receiptId,
          },
        })
        .then((response) => {
          this.rowPart = response.data.data;
          // console.log(this.rowPart[0]);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
        });
    },

    getMasterPartST: function() {
      this.rowParts = [];
      this.optionMasterST = [];
      this.masterST = [];
      // this.overlayFlag = true;
      this.isLoading = true; //skeleton true
      useNetw
        .get("api/master/stock-part", {
          params: {
            branchId: this.branchId,
          },
        })
        .then((response) => {
          // // console.log("Master Stock ==> " , response.data.data);
          this.optionMasterST = response.data.data;
          // // console.log(response);
        })
        .catch((err) => {
          if (err.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(
                err.response.data.message + "<br>" + err.response.data.message
              ),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.isLoading = false; //skeleton false
        });
    },

    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      this.branchId = user.branchId;

      const localDataBranch = [];
      const localDataBranchId = [];
      this.localData.forEach((item) => {
        localDataBranch.push(item), localDataBranchId.push(item.branchId);
      });
      this.localDataBranchUser = localDataBranch;
      this.localDataBranchIdUser = localDataBranchId;
      // // console.log(this.branchId);
    },

    customLabel({ partCode, nameTh }) {
      return `[${partCode}] - ${nameTh != null ? nameTh : ""}`;
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.optionsPO = filteredItems.length;
      this.currentPage = 1;
    },
    onRowSelectedPO(rowTest) {
      this.selected = rowTest;
      // this.getData();
    },
    selectAllRows() {
      this.$refs.selectableTablePO.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTablePO.clearSelected();
    },
    // eslint-disable-next-line no-unused-vars
    modalForm() {
      this.$v.$touch();
      this.submitted = true;
    },
    searchForm() {
      this.$v.$touch();
      this.submit = true;
    },
    poForm() {
      this.submitform = true;
      this.$v.$touch();
      if (this.$v.receipts.$invalid != true) {
        this.postReceipts();
      }
    },

    getSup: function() {
      // this.overlayFlag = true;
      this.isLoading = true; //skeleton true
      useNetw
        .get("api/master/suppliers", {
          params: {
            branchId: this.branchId,
          },
        })
        .then((response) => {
          this.rowSup = response.data.data;
          // // console.log(response);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.isLoading = false; //skeleton false
        });
    },

    handleChangePagePO(pagePO) {
      this.currentPagePO = pagePO;
      this.getPoData();
    },
    handlePageChangePO(active) {
      this.perPagePO = active;
      this.currentPagePO = 1;
      this.getPoData();
    },
    onFilteredPO(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowPO = filteredItems.length;
      this.currentPagePO = 1;
    },

    //! RC
    handleChangePageRC(pageRC) {
      this.currentPageRC = pageRC;
      this.getRCData();
    },
    handlePageChangeRC(active) {
      this.perPageRC = active;
      this.currentPageRC = 1;
      this.getRCData();
    },
    onFilteredRC(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowRC = filteredItems.length;
      this.currentPageRC = 1;
    },
  },
};
</script>

<template>
  <Layout>
    <div>
      <b-alert
        class="alert-fixed fixed-top rounded-1 m-4 bg-light"
        :show="dismissCountDown"
        dismissible
        fade
        @dismissed="dismissCountDown = 0"
        @dismiss-count-down="countDownChanged"
      >
        <p class="float-end">{{ dismissCountDown }} วินาที...</p>
        <p>แจ้งเตือน</p>
        <hr />
        <p>{{ resalert }}</p>
        <!-- <b-progress
        variant="warning"
        :max="dismissSecs"
        :value="dismissCountDown"
        height="4px"
      ></b-progress> -->
      </b-alert>
    </div>
    <b-overlay
      :show="isLoading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <PageHeader :title="title" :items="items" />
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <p class="card-title-desc"></p>
              <form class="needs-validation" @submit.prevent="poForm">
                <div class="row">
                  <div class="col-sm-6 col-md-4">
                    <div class="mb-3 position-relative">
                      <code>* </code><label for="branchId">สาขา :</label>

                      <multiselect
                        :disabled="statusRC == 'APV'"
                        id="branchId"
                        v-model="receipts.branchId"
                        :options="localDataBranchUser"
                        class=""
                        :class="{
                          'is-invalid':
                            submitform && $v.receipts.branchId.$error,
                        }"
                        label="nameTh"
                        :show-labels="false"
                        track-by="nameTh"
                      ></multiselect>
                      <div
                        v-if="submitform && $v.receipts.branchId.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.receipts.branchId.required">{{
                          message
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6 col-md-4">
                    <div class="mb-3 position-relative">
                      <label for="supId">ผู้แทนจำหน่าย :</label>

                      <multiselect
                        :disabled="statusRC == 'APV'"
                        id="supId"
                        v-model="receipts.supId"
                        :options="rowSup"
                        class=""
                        :class="{
                          'is-invalid': submitform,
                        }"
                        label="nameTh"
                        :show-labels="false"
                        track-by="nameTh"
                      ></multiselect>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-4">
                    <div class="mb-3 position-relative">
                      <code>* </code><label for="Date"> วันที่ :</label>
                      <!-- disabled="disabled"  -->
                      <date-picker
                        v-model="receipts.Date"
                        :disabled="statusRC == 'APV'"
                        :first-day-of-week="1"
                        format="YYYY-MM-DD"
                        value-type="format"
                        lang="en"
                        :class="{
                          'is-invalid': submitform && $v.receipts.Date.$error,
                        }"
                      ></date-picker>
                      <div
                        v-if="submitform && $v.receipts.Date.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.receipts.Date.required">{{
                          message
                        }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-6 col-md-2 col-sm-4">
                    <div class="mb-3 position-relative">
                      <code>* </code> <label for="vatPer"> ภาษี(%) :</label>
                      <!-- disabled="disabled"  -->
                      <input
                        id="vatPer"
                        v-model="receipts.vatPer"
                        :disabled="statusRC == 'APV'"
                        :min="1"
                        type="number"
                        class="form-control"
                        value="Otto"
                        :class="{
                          'is-invalid': submitform && $v.receipts.vatPer.$error,
                        }"
                      />
                      <div
                        v-if="submitform && $v.receipts.vatPer.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.receipts.vatPer.required">{{
                          message
                        }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-4">
                      <div class="mb-3 position-relative">
                        <label for="vatPer">คลังสินค้า :</label>
                        <multiselect
                          :disabled="statusRC == 'APV'"
                          id="supId"
                          v-model="receipts.stockId"
                          :options="rowParts"
                          class=""
                          :class="{
                            'is-invalid': submitform,
                          }"
                          label="nameTh"
                          :show-labels="false"
                          track-by="nameTh"
                        ></multiselect>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <label for="shippingNumber">Shipping Number :</label>
                        <input
                          :disabled="statusRC == 'APV'"
                          type="text"
                          class="form-control"
                          v-model="receipts.shippingNumber"
                        />
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-4">
                      <div class="mb-3 position-relative">
                        <label for="Date"> วันที่ Shipping  :</label>
                        <!-- disabled="disabled"  -->
                        <date-picker
                          :disabled="statusRC == 'APV'"
                          v-model="receipts.shippingDate"
                          :first-day-of-week="1"
                          format="YYYY-MM-DD"
                          value-type="format"
                          lang="en"
                        ></date-picker>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <label for="taxInvoiceNumber">Tax Invoice Number :</label>
                        <input
                          :disabled="statusRC == 'APV'"
                          type="text"
                          class="form-control"
                          v-model="receipts.taxInvoiceNumber"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-3 col-sm-4">
                      <div class="mb-3 position-relative">
                        <label for="Date">
                          วันที่ใบกำกับภาษี (Tax Invoice)
                         :</label>
                        <!-- disabled="disabled"  -->
                        <date-picker
                          :disabled="statusRC == 'APV'"
                          v-model="receipts.taxInvoiceDate"
                          :first-day-of-week="1"
                          format="YYYY-MM-DD"
                          value-type="format"
                          lang="en"
                        ></date-picker>
                      </div>
                    </div>

                    <div class="col-md-2">
                      <div class="mb-3 position-relative">
                        <label for="taxInvoiceNumber">เครดิต :</label>
                        <input
                          :disabled="statusRC == 'APV'"
                          type="number"
                          class="form-control"
                          v-model="receipts.credit"
                        />
                      </div>
                    </div>

                    <div class="col-md-3 col-sm-4">
                      <div class="mb-3 position-relative">
                        <label for="Date"> วันครบกำหนด  :</label>
                        <!-- disabled="disabled"  -->
                        <date-picker
                          :disabled="statusRC == 'APV'"
                          v-model="receipts.dueDate"
                          :first-day-of-week="1"
                          format="YYYY-MM-DD"
                          value-type="format"
                          lang="en"
                        ></date-picker>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md">
                      <!-- <button class="btn btn-success float-end" type="submit">
                      บันทึก
                    </button> -->
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <!-- end card -->
        </div>
      </div>
      <!-- end col -->

      <!-- //? form PO add to receipts -->

      <div class="row" v-if="isHidden">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <h4>
                  <strong
                    ><code>* </code>รายการอะไหล่ที่เพิ่มเข้าคลังแล้ว</strong
                  >
                </h4>
                <br />
              </div>
              <br />
              <div class="row">
                <div class="col-md-12">
                  <div class="mb-6 position-relative">
                    <div class="table-responsive mb-0">
                      <b-table
                        :items="rowsRC"
                        :fields="fieldsPartRC"
                        responsive="sm"
                        :per-page="perPageRC"
                        :current-page="1"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        :filter="filterRC"
                        :filter-included-fields="filterOnRC"
                        @filtered="onFilteredRC"
                      >
                        <template
                          v-slot:cell(action)="rowsRC"
                          v-if="statusRC != 'APV'"
                        >
                          <a
                            class="px-2 text-primary"
                            v-b-tooltip.hover
                            title="Edit"
                            @click="editPartPO(rowsRC)"
                            :disabled="statusRC == 'APV'"
                          >
                            <i class="uil uil-pen font-size-18"></i>
                          </a>
                          <a
                            class="px-2 text-danger"
                            v-b-tooltip.hover
                            title="Delete"
                            :disabled="statusRC == 'APV'"
                            @click="ConfirmDelPartPO(rowsRC)"
                          >
                            <i class="uil uil-trash-alt font-size-18"></i>
                          </a>
                        </template>

                        <!-- End edit table --> </b-table
                      ><br />

                      <div class="row">
                        <!-- <div class="col">
                                แสดงผล {{ vmFrom }} - {{ vmTo }} of {{ totalVm }} rows
                              </div> -->

                        <div class="col">
                          <div
                            class="
                                  dataTables_paginate
                                  paging_simple_numbers
                                  float-end
                                "
                          >
                            <ul class="pagination pagination-rounded mb-0">
                              <!-- pagination -->
                              <b-pagination
                                v-model="currentPageRC"
                                :total-rows="totalRC"
                                :per-page="perPageRC"
                                @change="handleChangePageRC"
                              ></b-pagination>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />

              <div class="row">
                <div class="col-md-5">
                  <label for="parts">อะไหล่ :</label>
                  <multiselect
                    disabled
                    v-model="partSelect"
                    :options="rowPart"
                    @input="popupModalAddPOwMulti(partSelect)"
                    label="partNameTh"
                    @search-change="getParts()"
                    :show-labels="false"
                    track-by="partNameTh"
                    :class="{
                      'is-invalid': submitform && $v.receipts.branchId.$error,
                    }"
                  ></multiselect>
                </div>
                <div class="col-3">
                  <label for="parts">&nbsp; :</label><br />
                  <button
                  :disabled="statusRC == 'APV'"
                    class="btn btn-primary"
                    @click="$refs['modalPO'].show()"
                  >
                    <i class="uil uil-search"> </i> เลือกจากใบสั่งซื้อ
                  </button>
                </div>
              </div>
              <br />
              <hr />

              <div class="row">
                <div class="card" v-show="isHidden">
                  <!--  -->
                  <b-overlay
                    :show="showOverlayFormConcludeRC"
                    spinner-variant="primary"
                    spinner-type="grow"
                    spinner-small
                    rounded="sm"
                  >
                    <div class="card-body">
                      <h4 class="card-title">
                        <h4><strong>สรุปยอดการรับอะไหล่เข้าคลัง</strong></h4>
                      </h4>
                      <hr />
                      <div class="row">
                        <div class="col-md-2">
                          รายละเอียดการรับ
                        </div>
                        <div class="col-md-2">
                          <div style="display: inline-block">
                            <div class="text-end">
                              ยอดรวมค่าอะไหล่ : <br />
                              ส่วนลดรวม(%) : <br />
                              ส่วนลดรวม(บาท) : <br />
                              ภาษีรวม(%) : <br />
                              ภาษีรวม(บาท) : <br />
                              สุทธิ : <br />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-2 text-end">
                          {{
                            Number(rcptTotalPrice)
                              .toFixed(2)
                              .toLocaleString()
                          }}
                          <br />
                          {{ rcptDcPer.toFixed(2) }}<br />
                          {{
                            Number(rcptDcPrice)
                              .toFixed(2)
                              .toLocaleString()
                          }}
                          <br />
                          {{ rcptVatPer.toFixed(2) }} <br />
                          {{ Number(rcptVatPrice).toLocaleString() }} <br />
                          {{
                            Number(
                              rcptTotalPrice + rcptVatPrice
                            ).toLocaleString()
                          }}
                        </div>
                        <div class="col-md-1 text-end">
                          บาท<br />
                          %<br />
                          บาท <br />
                          % <br />
                          บาท <br />
                          บาท
                        </div>
                        <!-- มูลค่ารวม -->
                        <div
                          class="col-5 bg-dark text-white m-0"
                          style="border-radius:10px;
                    margin:auto;
                    justify "
                        >
                          <div class="row align-items-center m-0">
                            <div class="col-12">
                              <div class="row">
                                <div class="col-md-3">
                                  มูลค่ารวม :
                                </div>
                                <div class="col-6 font-size-24">
                                  {{
                                    Number(
                                      rcptTotalPrice + rcptVatPrice
                                    ).toLocaleString()
                                  }}
                                </div>
                                <div class="col-3">
                                  บาท
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <br /><br />
                      <div class="row text-end">
                        <hr />
                        <div>
                          <button
                            class="btn btn-success"
                            @click="putRC()"
                            :disabled="statusRC == 'APV'"
                          >
                            เสร็จสิ้น-กลับสู่หน้าหลัก
                          </button>
                          |
                          <button
                            class="btn btn-success"
                            @click="comfirmPutStatusRC()"
                            :disabled="statusRC == 'APV'"
                          >
                            ยืนยันการรับ
                          </button>
                          <code v-show="statusRC == 'APV'">
                            ไม่สามารถแก้ไขรายการที่ยืนยันแล้วได้่</code
                          >
                        </div>
                      </div>
                    </div>
                  </b-overlay>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end table -->
      <b-modal
        title="เพิ่มอะไหล่เข้าคลัง"
        hide-footer
        size="md"
        centered
        ref="modalAddPartPO"
      >
        <div class="row text-center">
          <div class="col">
            <label for="amountPO">
              <b>
                รหัสใบสั้งซื้อ : {{ selectDataPO.poCode }} <br />
                อะไหล่ : {{ selectDataPO.partCode }} -
                {{ selectDataPO.partNameTh }} <br />
                คงเหลือ : {{ selectDataPO.accruedAmount }} <br />
                ราคา : {{ selectDataPO.pricePerOne }} บาท
              </b>
             :</label>
            <hr />
          </div>
        </div>

        <div class="row">
          <div class="col-4 text-end">
            <b>จำนวน :</b>
          </div>
          <div class="col-5">
            <input
              type="number"
              class="form-control"
              v-model="receipts.amountPartPO"
              id="amountP"
              style="text-align:center;"
            />
          </div>
          <div
            class="col-md-3 text-end"
            v-show="selectDataPO.accruedAmount < receipts.amountPartPO"
          >
            <code> !!! เกินจำนวน </code>
          </div>
        </div>

        <br />

        <div class="row">
          <div class="col-4 text-end">
            <b> ส่วนลด(%) : </b>
          </div>
          <div class="col-md-5">
            <input
              type="number"
              class="form-control"
              v-model="receipts.dcPer"
              id="dcPer"
              style="text-align:right;"
            />
          </div>
          <div class="col-3 text-end">
            <b>% </b>
          </div>
        </div>
        <br />

        <div class="row">
          <div class="col-4 text-end">
            <b> ส่วนลด (บาท) : </b>
          </div>
          <div class="col-5 text-end">
            <b>
              {{
                (receipts.dcPrice =
                  (receipts.dcPer / 100) *
                  (receipts.amountPartPO * selectDataPO.pricePerOne))
              }}
            </b>
          </div>
          <div class="col-3 text-end">
            <b>บาท</b>
          </div>
        </div>

        <div class="row">
          <div class="col-4 text-end">
            <b>
              ราคาหักส่วนลดแล้ว :
            </b>
          </div>
          <div class="col-5 text-end">
            <b>
              {{
                (receipts.netPrice =
                  receipts.amountPartPO * selectDataPO.pricePerOne -
                  (receipts.dcPer / 100) *
                    (receipts.amountPartPO * selectDataPO.pricePerOne))
              }}
            </b>
          </div>
          <div class="col-3 text-end">
            <b>
              บาท
            </b>
          </div>
        </div>
        <br />

        <div class="row">
          <div class="col-4 text-end">
            <b>ภาษี(%) : </b>
          </div>
          <div class="col-md-5">
            <input
              style="text-align:right;"
              type="number"
              class="form-control"
              v-model="receipts.vatPer"
              id="dcPer"
              disabled
            />
          </div>
          <div class="col-3 text-end">
            <b> % </b>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-4 text-end">
            <b>
              ภาษี(บาท) :
            </b>
          </div>
          <div class="col-5 text-end">
            <b>
              {{
                (receipts.vatPrice = (
                  (receipts.amountPartPO * selectDataPO.pricePerOne -
                    (receipts.dcPer / 100) *
                      (receipts.amountPartPO * selectDataPO.pricePerOne)) *
                  (receipts.vatPer / 100)
                ).toFixed(2))
              }}
            </b>
          </div>
          <div class="col-3 text-end">
            <b>บาท</b>
          </div>
        </div>

        <div class="row">
          <div class="col-4 text-end">
            <b>
              ยอดรวมภาษี :
            </b>
          </div>
          <div class="col-5 text-end">
            <b>
              {{
                (receipts.grandTotalPrice =
                  receipts.amountPartPO * selectDataPO.pricePerOne -
                  (receipts.dcPer / 100) *
                    (receipts.amountPartPO * selectDataPO.pricePerOne) +
                  (receipts.amountPartPO * selectDataPO.pricePerOne -
                    (receipts.dcPer / 100) *
                      (receipts.amountPartPO * selectDataPO.pricePerOne)) *
                    (receipts.vatPer / 100))
              }}
            </b>
          </div>
          <div class="col-3 text-end">
            <b>
              บาท
            </b>
          </div>
        </div>

        <hr />

        <div class="row">
          <div class="col-lg-12">
            <div class="text-end">
              <button
                class="btn btn-success"
                @click="postPartPO"
                :disabled="selectDataPO.accruedAmount < receipts.amountPartPO || receipts.amountPartPO == 0
                "
              >
                เพิ่มอะไหล่เข้าคลัง
              </button>
            </div>
          </div>
        </div>
      </b-modal>

      <b-modal
        title="แก้ไขการรับอะไหล่เข้าคลัง"
        hide-footer
        size="md"
        centered
        ref="modalEditPartPO"
      >
        <div class="row text-center">
          <div class="col">
            <label for="amountPO">
              <b>
                รหัสใบสั้งซื้อ : {{ selectDataPO.poCode }} <br />
                อะไหล่ : {{ selectDataPO.partCode }} -
                {{ selectDataPO.partNameTh }} <br />
                คงเหลือ : {{ selectDataPO.accruedAmount }} <br />
                ราคา : {{ selectDataPO.pricePerOne }} บาท
              </b>
             :</label>
            <hr />
          </div>
        </div>

        <div class="row">
          <div class="col-4 text-end">
            <b>จำนวน :</b>
          </div>
          <div class="col-5">
            <input
              type="number"
              class="form-control"
              v-model="receipts.amountPartPO"
              id="amountP"
              style="text-align:center;"
            />
          </div>
          <div
            class="col-md-3 text-end"
            v-show="selectDataPO.accruedAmount < receipts.amountPartPO"
          >
            <code> !!! เกินจำนวน </code>
          </div>
        </div>

        <br />

        <div class="row">
          <div class="col-4 text-end">
            <b> ส่วนลด(%) : </b>
          </div>
          <div class="col-md-5">
            <input
              type="number"
              class="form-control"
              v-model="receipts.dcPer"
              id="dcPer"
              style="text-align:right;"
            />
          </div>
          <div class="col-3 text-end">
            <b>% </b>
          </div>
        </div>
        <br />

        <div class="row">
          <div class="col-4 text-end">
            <b> ส่วนลด (บาท) : </b>
          </div>
          <div class="col-5 text-end">
            <b>
              {{
                (receipts.dcPrice =
                  (receipts.dcPer / 100) *
                  (receipts.amountPartPO * selectDataPO.pricePerOne))
              }}
            </b>
          </div>
          <div class="col-3 text-end">
            <b>บาท</b>
          </div>
        </div>

        <div class="row">
          <div class="col-4 text-end">
            <b>
              ราคาหักส่วนลดแล้ว :
            </b>
          </div>
          <div class="col-5 text-end">
            <b>
              {{
                (receipts.netPrice =
                  receipts.amountPartPO * selectDataPO.pricePerOne -
                  (receipts.dcPer / 100) *
                    (receipts.amountPartPO * selectDataPO.pricePerOne))
              }}
            </b>
          </div>
          <div class="col-3 text-end">
            <b>
              บาท
            </b>
          </div>
        </div>
        <br />

        <div class="row">
          <div class="col-4 text-end">
            <b>ภาษี(%) : </b>
          </div>
          <div class="col-md-5">
            <input
              style="text-align:right;"
              type="number"
              class="form-control"
              v-model="receipts.vatPer"
              id="dcPer"
              disabled
            />
          </div>
          <div class="col-3 text-end">
            <b> % </b>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-4 text-end">
            <b>
              ภาษี(บาท) :
            </b>
          </div>
          <div class="col-5 text-end">
            <b>
              {{
                (receipts.vatPrice = (
                  (receipts.amountPartPO * selectDataPO.pricePerOne -
                    (receipts.dcPer / 100) *
                      (receipts.amountPartPO * selectDataPO.pricePerOne)) *
                  (receipts.vatPer / 100)
                ).toFixed(2))
              }}
            </b>
          </div>
          <div class="col-3 text-end">
            <b>บาท</b>
          </div>
        </div>

        <div class="row">
          <div class="col-4 text-end">
            <b>
              ยอดรวมภาษี :
            </b>
          </div>
          <div class="col-5 text-end">
            <b>
              {{
                (receipts.grandTotalPrice =
                  receipts.amountPartPO * selectDataPO.pricePerOne -
                  (receipts.dcPer / 100) *
                    (receipts.amountPartPO * selectDataPO.pricePerOne) +
                  (receipts.amountPartPO * selectDataPO.pricePerOne -
                    (receipts.dcPer / 100) *
                      (receipts.amountPartPO * selectDataPO.pricePerOne)) *
                    (receipts.vatPer / 100))
              }}
            </b>
          </div>
          <div class="col-3 text-end">
            <b>
              บาท
            </b>
          </div>
        </div>

        <hr />

        <div class="row">
          <div class="col-lg-12">
            <div class="text-end">
              <button
                class="btn btn-success"
                @click="putPartPO"
                :disabled="selectDataPO.accruedAmount < receipts.amountPartPO"
              >
                แก้ไข
              </button>
            </div>
          </div>
        </div>
      </b-modal>
    </b-overlay>

    <b-modal
      title="เลือกใบสั่งซื้ออะไหล่"
      hide-footer
      size="xl"
      centered
      ref="modalPO"
    >
      <div class="row">
        <div class="col-md-10">
          <strong><code>* </code>รหัสใบสั่งซื้ออะไหล่</strong>
          <div class="row">
            <div class="col-md-5">
              <input
                :disabled="statusRC == 'APV'"
                type="text"
                v-model="receipts.poCode"
                class="form-control"
              />
            </div>
            <div class="col-md-auto">
              <button
                class="btn btn-primary"
                @click="getPoData"
                :disabled="statusRC == 'APV'"
              >
                ค้นหาด้วยใบ PO
              </button>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-md-12">
          <div class="mb-6 position-relative">
            <div class="table-responsive mb-0">
              <b-table
                :items="optionsPO"
                :fields="fieldsPartPO"
                responsive="sm"
                :per-page="perPagePO"
                :current-page="1"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filterPO"
                :filter-included-fields="filterOnPO"
                @filtered="onFilteredPO"
                ref="selectableTablePO"
                selectable
                select-mode="single"
                @row-selected="onRowSelectedPO"
              >
                <template v-slot:cell(action)="optionsPO">
                  <a
                    class="px-2 text-primary"
                    v-b-tooltip.hover
                    title="Edit"
                    @click="popupModalAddPO(optionsPO)"
                  >
                    <i class="uil uil-plus-square font-size-18"></i>
                    เพิ่มเข้าคลัง
                  </a>
                </template>

                <!-- End edit table --> </b-table
              ><br />

              <div class="row">
                <!-- <div class="col">
                                แสดงผล {{ vmFrom }} - {{ vmTo }} of {{ totalVm }} rows
                              </div> -->

                <div class="col">
                  <div
                    class="
                                  dataTables_paginate
                                  paging_simple_numbers
                                  float-end
                                "
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination
                        v-model="currentPagePO"
                        :total-rows="totalPO"
                        :per-page="perPagePO"
                        @change="handleChangePagePO"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </Layout>
</template>
